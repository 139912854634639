const faqs = [
  {
    question: "I’m worried about security.",
    answer:
      "There is no such thing as 100 percent safety online. But, you can do a lot to reduce the risks and make it difficult for malicious parties to target you. If you're worried about keeping your mail to us private you can use <a href='https://www.openpgp.org'>PGP encryption</a> to make sure only we can read it. <a href='https://support.mozilla.org/en-US/kb/openpgp-thunderbird-howto-and-faq'>Read here</a> to find out how to do so using Thunderbird.<br/><br/> If you want to know more about online privacy and how to protect yourself in general check out the  resources that <a href='https://www.accessnow.org/help/#community-resources'>Access Now</a>, the <a href='https://ssd.eff.org/'>Electronic Frontier Foundation</a>, and the <a href='https://digitalfirstaid.org/'>Digital First Aid Kit</a> has put together."
  },
  {
    question: "My account was hacked. What can I do?",
    answer:
      "If you think your account has been compromised you should quickly change all passwords and reach out to one of our partner organisations, <a href='https://www.civicert.org/'>Civicert</a>. Digital First Aid Kit also has <a href='https://digitalfirstaid.org/en/topics/account-access-issues/'>specific instructions</a> about if you lose access to your accounts"
  },
  {
    question:
      "Need help reinstating social media account or content, or need content removed?",
    answer:
      "<a href='https://mnemonic.org/en/contact-us'>Get in touch</a> with us or reach out to our partners at <a href='https://www.accessnow.org/help/#community-resources'>Access Now</a> or <a href='https://www.civicert.org/'>Civicert</a>."
  },
  {
    question: "Important content is disappearing?",
    answer:
      "That's right. Every day millions of videos get uploaded to social media and to make sure these videos don’t breach company guidelines or local and international laws social media companies filter them. Content moderators and algorithms are deployed to flag and moderate content. This work is important as it keeps violent imagery, hate speech or otherwise objectionable content from reaching social media users. However, a lack of context and training can lead to content with extreme value to be removed and possibly lost forever. Mnemonic helps valuable content get reinstated."
  },
  {
    question:
      "How can I stop my content being removed from social media platforms in the future?",
    answer:
      "The short answer is that even if you do everything right, your content could be removed. That’s why we do advocacy on <a href='https://mnemonic.org/en/our-work#content-moderation'>content moderation</a>.<br/><br/> We know that major platforms do content moderation so quickly that they miss important information. Fortunately, there are a few things you can do.<br/><br/>Make sure to label your content as clearly as possible to show that you are documenting human rights abuses or sharing newsworthy or educational content.<br/><br/>Do your best to keep up to date on terms of service for <a href='https://www.youtube.com/static?template=terms'>Youtube</a>, <a href='https://twitter.com/en/tos'>Twitter</a> and <a href='https://www.facebook.com/terms.php'>Facebook</a> here. We will also do our best to inform you of important changes.<br/><br/>Finally, it’s very important to preserve the original copies of important content you are uploading to social media platforms. Witness’s <a href='https://www.witness.org/portfolio_page/video-as-evidence-basic-practices/'>Video as Evidence</a> guide provides suggestions on how to do this."
  },
  {
    question: "I want to start my own archive like your archive.",
    answer:
      "Great, it can be daunting to know where to get started. We have set up four archives, <a href='https://sudanesearchive.org/'>Sudan Archive</a>, <a href='https://yemeniarchive.org/'>Yemeni Archive</a>, <a href='https://syrianarchive.org/'>Syrian Archive</a>, <a href='https://ukrainianarchive.org/'>Ukrainian Archive</a> and are working with groups on creating others. Check out our <a href='https://mnemonic.org/en/about/methods'>tools and methods</a> to do it yourself. If you need help to do so, feel free to email us describing your situation. <a href='https://mnemonic.org/en/contact-us'>Please let us know</a>."
  },
  {
    question: "I want to know how to archive a few videos.",
    answer:
      "You have a few videos you feel could be vulnerable if left solely on social media. To save videos directly to your personal hard drive you can use the <a href='https://youtube-dl.org/'>youtube-dl</a> or the Internet Archive. Be mindful that if using services like the Internet Archive, this content will be publicly searchable and accessible, so archiving potentially sensitive information using these services might not be the best solution for content that could potentially put you or others at risk."
  },
  {
    question: "I want to archive a complete website?",
    answer:
      "Look into the resources put together by <a href='https://rhizome.org/software/'>Rhizome</a>. If you are looking to publicly archive a web page, you can use free and publicly available services like the Internet Archive. Be mindful that this content will be publicly searchable and accessible, so archiving potentially sensitive information using these services might not be the best solution for content that could potentially put you or others at risk."
  },
  {
    question: "How should I get in touch with you?",
    answer:
      "Check out our <a href='https://mnemonic.org/en/contact-us'>contact page here</a>"
  },
  {
    question: "Will my identity be protected if I reach out?",
    answer:
      "We will not share your identity with any third parties. However, there is no such thing as 100 percent safety online so make sure your communication is secure, encrypt your emails using <a href='https://www.openpgp.org'>PGP encryption</a> to make sure only we can read it. <a href='https://support.mozilla.org/en-US/kb/openpgp-thunderbird-howto-and-faq'>Read here</a> to find out how to do so using Thunderbird. If you want to know more about online privacy and how to protect yourself in general check out the resources that <a href='https://www.accessnow.org/help/#community-resources'>Access Now</a>, the <a href='https://ssd.eff.org/'>Electronic Frontier Foundation</a>, and the <a href='https://digitalfirstaid.org/'>Digital First Aid Kit</a> has put together."
  },
  {
    question: "To what level do you archive material?",
    answer:
      "Read more about our methodology <a href='https://mnemonic.org/en/about/methods'>here</a>."
  }
];

export default faqs;
